import React from 'react';
import './comp.css'
import logo from '../images/logo.webp'

const Landing = () => {
  return (
    <div className='landingPage'>
        <h1>OROGARE TECHNICAL AND VOCATIONAL COLLEGE</h1>
        <img src={logo} alt="school logo" />
        <p>UFUNDI NI MAISHA</p>
    </div>
  )
}

export default Landing