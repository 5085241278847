import React from 'react'
import './comp.css'

const MottoVis = (props) => {
    const { title , motto } = props
  return (
    <div className='mottoVis'>
        <h1>{title}</h1>
        <p>{motto}</p>
    </div>
  )
}

export default MottoVis