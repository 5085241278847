import React from 'react'
import './sec.css'
import main from '../images/group/school.webp'
import prinsi from '../images/prinsi.webp'
import Dean from '../images/dean.webp'
import registrar from '../images/registrar.webp'
import deputy from '../images/deputy.webp'
import bogChair from '../images/bog.webp'
import AboutParts from '../Components/AboutParts'

const AboutSection = ({articles}) => {
    
    return (
        <div className='page' id='aboutSection'>
            <h1 className='h1'>About Us</h1>
            <AboutParts title={"The Institute"} name="Since 2021" paragraph={articles.school} img={main} />
            <AboutParts title={"BOG Chair"} name="Mr. Isaac Ombati " paragraph={articles.bog} img={bogChair} />
            <AboutParts title={"Principal"} name="Mr. Robinson Kambi" paragraph={articles.principal} img={prinsi} />
            <AboutParts title={"Deputy Principal"} name="Mrs. Mutai Chepkoech Gladys" paragraph={articles.deputy} img={deputy} />
            <AboutParts title={"Registrar"} name="Mr. Shadrack Kibet Chepkwony" paragraph={articles.registrar} img={registrar} />
            <AboutParts title={"Dean of Students"} name="Mrs. Ann Kemunto Iyoga" paragraph={articles.dean} img={Dean} />
        
        </div>
    )
}

export default AboutSection
