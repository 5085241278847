import React from 'react'
import { captions } from '../Data/captions';
import './page.css'

const SchoolGallery = () => {
    function importAll(r) {
        let images = [];
        r.keys().map(item => { return images.push(r(item)) });
        return images;
    }
    const images = importAll(require.context('../images/group', false, /\.(webp|png|jpe?g|svg)$/));
  return (
    <div className='page' id='galleryPage'>
        <h1 className='h1'>A Gallery of Us...</h1>
        <div className='galleryList'>
            {images.map((img, indx) => 
            <div 
                style={style}
                key={indx} className="galleryObject">
                <img 
                    src={img}
                    alt="img"
                />
                <h2 style={style.caption}>{captions[indx]}</h2>
            </div>
            )}
        </div>
    </div>
  )
}

export default SchoolGallery

const style = {
    caption: {
        top: "5%",
        width: "100%",
        color: "white",
        textAlign: "right",
        position: "absolute",
        textShadow: "0px 0px 12px black",
    }
}