import React from 'react'
import Landing from '../Components/Landing'
import Location from '../PageSections/Location'
import Gallery from '../PageSections/GallerySection'
import IntroSection from '../PageSections/IntroSection'
import IntakeSection from '../PageSections/IntakeSection'
import CoursesSection from '../PageSections/CoursesSection'
import './page.css'


const Home = ({schoolParagraph}) => {

  return (
    <div className='page' id='homepage'>
        <Landing />
        <IntroSection schoolParagraph={schoolParagraph} />
        <CoursesSection />
        <Gallery />
        <IntakeSection />
        <Location />
    </div>
  )
}

export default Home