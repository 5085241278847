import React from 'react'
import './comp.css'

const ListCourses = (props) => {
    const thisLevel = props.course

    return (
        <div className='listCourses'>
            <h1>{thisLevel[0].level}</h1>
            <h3>Minimum Requirements</h3>
            <ol className='qualif'>
                {typeof(thisLevel[0].qualification) === 'object' ?  thisLevel[0].qualification.map((req, index)=><li key={index}>
                    {req}
                </li>) : <li>{thisLevel[0].qualification}</li>}
            </ol>
            <h3>Courses</h3>
            <ol className='courses'>
                {thisLevel.map((level, indx)=><li key={indx}>
                    <span>
                        <p>{level.course}</p>
                        <em>{level.duration}</em>
                    </span>
                </li>)}
            </ol>
        </div>
    )
}

export default ListCourses