import React from 'react'
import './sec.css'
import logo from '../images/logo.webp'
import { MdCopyright, MdFacebook, MdHome, MdMail} from 'react-icons/md'
import { BsPhoneFill, BsTwitter } from 'react-icons/bs'
import CallActions from '../Components/CallActions'

const Footer = () => {
  return (
    <footer>
        <img className='logo' src={logo} alt='logo' />
        <div className='contacts' >
            <h1>Contact Us</h1>
            <div>
                <CallActions icon={<BsPhoneFill />} link={
                    "tel://+254791964600"
                } />
                <p>0791964600</p>
            </div>
            <div>
                <CallActions icon={<MdHome />}  />
                <p>P.O BOX 356-40204, OGEMBO</p>
            </div>
            <div>
                <CallActions icon={<BsTwitter />} link={
                    "twitter.com"
                } />
                <p>@Orogare_TVC</p>
            </div>
            <div>
                <CallActions icon={<MdMail />} link={
                    "mailto://musictyche@gmail.com"
                } />
                <p>orogaretvc@gmail.com</p>
            </div>
            <div>
                <CallActions icon={<MdFacebook />} link={
                    "facebook.com"
                } />
                <p>@Orogare_TVC</p>
            </div>
            
        </div>
        <b style={{heigh: "3em", lineHeight: "3em"}}>{new Date().getFullYear()}<MdCopyright /> Orogare</b>
    </footer>
  )
}

export default Footer
