import React from 'react'
import { months } from './../CustomHooks/getIntakeDates';
import './comp.css'
import vacancies from '../Data/vacancies';

const VacancyBanner = ({vacancy: {jobTitle, level, noOfVac, deadline}, setMessage}) => {
    const message = vacancies.specialInstructions
    return (
    <div className='vacBanner' onClick={()=>setMessage(message)}>
        <div id="vac-cont-1">
            <p>Deadline</p>
            <h1>{deadline.split('-')[2]}</h1>
            <p>{months[new Date(deadline).getMonth()]} {deadline.split("-")[0]}</p>
        </div>
        <div id="vac-cont-2">
            <span><p>Job Title: </p><p>{jobTitle}</p></span>
            <span><p>Level: </p> <p>{level.reduce((lev, cur)=>lev + "/ " + cur)}</p></span>
            <span><p>Vacancies: </p><p>{noOfVac}</p></span>
        </div>
    </div>
    )
}

export default VacancyBanner