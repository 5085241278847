
import { useState } from 'react'
import bog from './bogchair.txt'
import princi from './principal.txt'
import school from './school.txt'
import dean from './dean.txt'  
import deputy from './deputy.txt' 
import registrar from './registrar.txt'

       
let fileNames = ['principal.txt', 'school.txt', 'dean.txt', 'deputy.txt', 'registrar.txt','bogchair.txt']
let files = [princi, school, dean, deputy, registrar, bog]


const useArticles = () => {
    const [articles, setArticle] = useState({
        principal: "",
        school: "",
        dean: "", 
        deputy: "",
        registrar: "",
        bog: ""
    })

    async function getFiles(){
        for(let file of files){
            await fetch(file)
            .then(async(r) => {
                await r.blob().then(p=>{
                    let f = new File([p], fileNames[files.indexOf(file)])
                    let r = new FileReader()
                    r.readAsText(f)
                    r.onloadend = () =>{
                        setArticle(p=>({...p, [Object.keys(articles)[files.indexOf(file)]]: r.result}))
                    }
                })
            })
        }
        return
    }
    
    return { articles: articles, saveArticles: getFiles}
    
}

export default useArticles
