import React, { useState } from 'react'
import './page.css'
import VacancyBanner from '../Components/VacancyBanner'
import vacancies from '../Data/vacancies'
import Notification from '../Components/Notification'

const Careers = () => {
  let vacancies_ = isActive(vacancies.deadline) ? vacancies.vacancies : []
  const [message, setMessage] = useState("")

  function isActive(date){
    const theDate = new Date(date).setHours(0, 0, 0, 0)
    return Date.now() < theDate
  }

  let noVac = <h1 style={{margin: '20%'}}>No Job openings Available, Check later date</h1>
  
  return (
    <div className='page' id='careerPage'  >
        {vacancies_.length < 1 ? noVac :
        <>
        <Notification message={message} setMessage={setMessage} />
        <h1 className='h1'>Open Vacancies</h1>
        {vacancies.docs && <p id="doc-download">For more instructions, download and read <a href={vacancies.docs} download >This pdf</a></p>}
        <div className='careers'>
          {vacancies_.map((vac, i)=><VacancyBanner key={i} vacancy={vac} setMessage={setMessage} />)}
        </div>
        </>
        }
    </div>
  )
}

export default Careers

export const emptyPageStyle={
    display: 'flex',
    justifyContent: 'space-around'
}
