import React from 'react'
import './comp.css'
import { useNavigate } from 'react-router-dom'
import logo from '../images/logo.webp'

const SchoolLogo = () => {
    const navigate = useNavigate()
  return (
    <div className='schoolLogo' onClick={()=>navigate('/')}>
        ORTVC
        <img src={logo} alt="otvc icon" />
    </div>
  )
}

export default SchoolLogo