import React from 'react'
import './comp.css'
import Button1 from './Button1'
import { getIntakeDate, months } from '../CustomHooks/getIntakeDates'

const IntakeBanner = ({month}) => {
    const intake = getIntakeDate(month)
    const today = Date.now()

    const isLate = () => {
        const endDate = new Date(intake.end).setHours(0, 0, 0, 0)
        return today > endDate
    }
    const isEarly = () =>{
        const startDate = new Date(intake.start).setHours(0, 0, 0, 0)
        return today < startDate
    }

  return (
    <div className='intakeBanner'>
        <h1>{months[month]} Intake</h1>
        <span>
            <p>Starts: </p>
            <b>{intake.start}</b>
        </span>
        <span>
            <p>Closes: </p>
            <b>{intake.end}</b>
        </span>
        <Button1 txt={isLate() ? 
            "Closed" : isEarly() ? "Not Open yet" :
            "Register Now"} 
            path={isLate() ? "./" : isEarly() ? "./" : '/application'} />
    </div>
  )
}

export default IntakeBanner