import React, { useState } from 'react'
import './comp.css'

const AboutParts = ({img, name, title, paragraph}) => {
    const [isPhone, setIsPhone] = useState(false)
    
    window.addEventListener('load', ()=>{
        setIsPhone(window.innerWidth <= 727)
    })
    window.addEventListener('resize', ()=>{
        setIsPhone(window.innerWidth <= 727)
    })

    // const par1 = () =>paragraph.split(".").slice(0, 3).join(".")
    const par1 = () => {
        let clip = paragraph.slice(0, paragraph.length*0.375)
        return clip.slice(0, clip.lastIndexOf("."))+ ". "
    }
    // const par2 = () => paragraph.split(".").slice(3, paragraph.split(".").length - 1).join(".")
    const par2 = () => paragraph.split(par1()).join("")

    const listFilter = (doc) => {
        return doc.split('/').map((l, i)=><span key={i}>{l}<br/></span>) || doc
    }

    return (
    <div className='aboutParts'>
        <section>
            <div className='img'>
                <img src={img} alt={title} />
            </div>
            <h2>{title}</h2>
            <h5>{name}</h5>
            {!isPhone && <p>{listFilter(par1())}<br/>. </p>}
            {/* {!isPhone && <p>{par1()}</p>} */}
            {isPhone && <p>{listFilter(paragraph)}</p>}
        </section>

        {!isPhone && 
        <section>
            <p>{listFilter(par2())}<br/></p>
        </section>}

    </div>
  )
}

export default AboutParts