
import Home from './pages/Home';
import Careers from './pages/Careers';
import Navbar from './Components/Navbar';
import Footer from './PageSections/Footer';
import Applications from './pages/Applications';
import { Route, Routes, useLocation} from 'react-router-dom';
import SchoolGallery from './pages/SchoolGallery';
import './App.css';
import AboutSection from './PageSections/AboutSection';
import { useEffect, useRef } from 'react';
import useArticles from './Data/useArticles';
import StaffPage from './pages/StaffPage';
import ApplicationDocs from './Components/ApplicationDocs';

function App() {
    const loadCount = useRef(0)
    const { articles, saveArticles } = useArticles()

    useEffect(()=>{
        // only run a max of twice
        if(loadCount.current < 2) saveArticles()
        loadCount.current ++
    }, [loadCount, saveArticles])

      // reset scroll height 😁
const lokeshen = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [lokeshen]);

  return (
    <div className="App" >
        <Navbar /> 
        <Routes>
            {/* for the article portion on the main page */}
            <Route exact path='/' element={<Home schoolParagraph={articles.school} />} />
            <Route exact path='/about' element={<AboutSection articles={articles} />} />
            <Route exact path='/application' element={<ApplicationDocs />} />
            <Route exact path='/gallery' element={<SchoolGallery />} />
            <Route exact path='/careers' element={<Careers />} />
            <Route exact path='/staff' element={<StaffPage />} />
            <Route exact path='/tenders' element={<StaffPage />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
