import React from 'react'
import IntakeBanner from '../Components/IntakeBanner'
import './sec.css'

const IntakeSection = () => {

  return (
    <div className='pageSection' id='intakeSection'>
        <h1 className='h1'>Intakes</h1>
        <div className='' style={style}>
            <IntakeBanner month={0} />
            <IntakeBanner month={4} />
            <IntakeBanner month={8} />
        </div>
        <div>
            
        </div>
    </div>
  )
}

export default IntakeSection

const style = {
    gap: '2%',
    width: "90%",
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
}
