import React from 'react'
import { useNavigate } from 'react-router-dom'
import './comp.css'

const Button1 = ({txt, path}) => {
    const navigate = useNavigate()

    const click = () =>{
        if(path)return navigate(path)
    }
  return (
    <button className='button1' onClick={click}>
        {txt}
    </button>
  )
}

export default Button1