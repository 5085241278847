import React from 'react'
import './sec.css'
import { courses } from '../Data/courses'
import ListCourses from '../Components/ListCourses'

const CoursesSection = () => {
    const levels = Array.from(new Set(courses.map(val=>val.level)))
    
  return (
    <div className='pageSection' id='coursesSection'>
        <h1 className='h1'>Courses</h1>
        <div className='courses'>
            {levels.map((level, indx)=><ListCourses 
                key={indx}
                course={courses.filter(val=>val.level === level)}
                courses={ Array.from(new Set(courses.map(val=>
                    (val.level === level) && val.course
                ))) }
            />)}
        </div>
    </div>
  )
}

export default CoursesSection