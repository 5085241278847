import React from 'react'
import construction from '../images/construction.png'
import { emptyPageStyle } from './Careers'

const StaffPage = () => {
  return (
    <div className='page' id='staffPage' style={emptyPageStyle} >
        <div style={{margin: 'auto', textAlign: 'center', width: "90%"}}>
            <h1>Coming Soon</h1>
            <img style={{width: "100%", maxWidth: "300px"}} src={construction} alt="construction" />
        </div>
        
    </div>
  )
}

export default StaffPage