import React, { useState } from 'react'
import MobileDropdown from './MobileDropdown'
import NavButtons from './NavButtons'
import SchoolLogo from './SchoolLogo'

const Navbar = () => {
    const [isPhone, setIsPhone] = useState(window.innerWidth < 830)

    "load resize".split(" ").forEach(e=>window.addEventListener(e, ()=>{
        setIsPhone(window.innerWidth < 830)
    }))

  return (
    <nav>
        <SchoolLogo />
        {isPhone ? <MobileDropdown /> : <NavButtons />}
    </nav>
  )
}

export default Navbar