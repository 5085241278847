
import docs from './FINAL ADVERT 28.03.2023 TVET.pdf'

let vacancies = {
    docs: docs,
    deadline: '2023-4-17',
    specialInstructions: "Hand delivery to the instituion addressed to the principal, Orogare TVC, P.O BOX 356-40204, Ogembo",
    vacancies: [
        {
            jobTitle: "Building Technology",
            level: ["HND"],
            noOfVac: 1,
            deadline: "2023-4-17" 
        },
        {
            jobTitle: "Civil Engineering",
            level: ["HND"],
            noOfVac: 1,
            deadline: "2023-4-17" 
        },
        {
            jobTitle: "Clothing and Textile/Fashion and Design ",
            level: ["Diploma"],
            noOfVac: 1,
            deadline: "2023-4-17" 
        },
        {
            jobTitle: "Electrical Engineering {Power Option}",
            level: ["HND"],
            noOfVac: 1,
            deadline: "2023-4-17" 
        },
          {
            jobTitle: "Food and Beverage",
            level: ["Degree"],
            noOfVac: 1,
            deadline: "2023-4-17" 
        }
        
    ]
}

export default vacancies
