import React from 'react'
import './comp.css'

const CallActions = (props) => {
    const {icon, link} = props
  return (
    <a href={link} className="callActionBtn">
        {icon}
    </a>
  )
}

export default CallActions