import React from 'react'
import './comp.css'
import NavLinkBtn from './NavLinkBtn'

const NavButtons = () => {

  return (
    <div className='navButtons'>
        <NavLinkBtn txt={"HOME"} path={'/'} />
        <NavLinkBtn txt={'ABOUT'} path={'/about'} />
        <NavLinkBtn txt={"ADMISSION PORTAL"} path={'/application'} />
        <NavLinkBtn txt={"CAREERS"} path={'/careers'} />
        <NavLinkBtn txt={"STAFF"} path={'/staff'} />
        <NavLinkBtn txt={"TENDERS"} path={'/tenders'}/>
    </div>
  )
}

export default NavButtons
