
/**
 * @param value a value you want to test if is of type undefined
 * @returns boolean
 */
export const nU = (value) =>{
    return typeof(value) !== 'undefined'
} 


export let lorem = "Lorem ipsum dolor sit amet. Et officiis molestias sit tenetur necessitatibus ut sint natus aut cupiditate incidunt. A voluptatem adipisci sed quibusdam maxime et alias similique? Et assumenda saepe et explicabo voluptatibus sed maiores harum ex recusandae numquam sit quia molestiae et dolorem quos. In aspernatur galisum vel voluptates iusto eum maxime quidem sed dolor necessitatibus est possimus sunt eum fugit enim? Sit reprehenderit accusamus et dolor totam aut quam dolor qui commodi voluptas est libero similique? Vel delectus eius et saepe totam sed dolorem molestiae qui enim odit. 33 galisum nostrum est autem beatae sit nostrum minima aut deleniti enim. Non quia omnis et nihil pariatur est saepe culpa est distinctio libero. Aut pariatur eveniet eos dolorem dolore sit ipsa reiciendis. Et voluptatum fugiat est quam asperiores vel repellat suscipit ab minus autem qui voluptatem voluptatem quo neque fuga."

