import React from 'react'
import './comp.css'

const Notification = ({message, setMessage}) => {

    setTimeout(() => {
        setMessage("")
    }, 10000);


    if(message)return (
      <div className='notification'>
        <h3>Notification</h3>
        <p>{message}</p>
      </div>
    )
}

export default Notification