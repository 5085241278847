import React from 'react'
import './sec.css'
import MyCarousel from '../Components/MyCarousel'
import Button1 from '../Components/Button1'

const GallerySection = () => {
  return (
    <div className='pageSection' id='gallerySection'>
        <h1 className='h1'>A few Snapshots...</h1>
        <MyCarousel />
        <Button1 txt="View All" path={"/gallery"} />
    </div>
  )
}

export default GallerySection