import React from 'react'
import './comp.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { nU } from '../functions';

const NavLinkBtn = (props) => {
    const lokeshen = useLocation();
    const navigate = useNavigate();
    let { txt, path, scroll } = props;

    const click = () => {
        if(nU(scroll)) return document
        if(lokeshen.pathname !== path)
            navigate(path);
    }
  return (
    <button onClick={click} className="navLinkBtn" >
        {txt}
        <p style={{
            width: lokeshen.pathname === path ? "100%" : "0px",
            borderBottom: lokeshen.pathname === path ? "4px solid #17c71d" : "4px solid transparent"
        }}
        ></p>
    </button>
  )
}

export default NavLinkBtn