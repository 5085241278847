
export const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
]

function getWeek(dateP) {
    var d = dateP;
    var date = d.getDate();
    var day = d.getDay();
    var weekOfMonth = Math.ceil((date - 1 - day) / 7);
    if(weekOfMonth === 1) return true
    return false
 }
 
 function getDayName(dateStr, locale){
    var date = dateStr;
    return date.toLocaleDateString(locale, { weekday: 'long' });        
 }
  
  
export const getIntakeDate = (monthIndx) => {
    for(let i = 1; i < 31; i++){
        let aDate = new Date(`${months[monthIndx]} ${i}, ${new Date().getFullYear()}`)
        if(getWeek(aDate, i)){
            if(getDayName(aDate, 'en') === 'Monday') return {start: aDate.getDate() + ' ' + months[monthIndx] + ' ' + aDate.getFullYear(),
                end: (aDate.getDate() + 7) + ' ' + months[monthIndx] + ' ' + aDate.getFullYear()
            }
        }
    }
 }
