import React from 'react';
import Button1 from '../Components/Button1';
import MottoVis from '../Components/MottoVis';
import './sec.css'

const IntroSection = ({schoolParagraph}) => {

    // const articles = lorem + lorem
    const motto = "Ufundi Ni Maisha"
    const vision = "To be a center of excellence in provision of quality technical skills, knowledge, research and innovation";
    const mission = "To offer demand-driven courses to mitigate on challenges of a dynamic society";
  return (
    <div className='pageSection' id='introSection'>
        <div className='about'>
            <h1 className='h1' style={{marginLeft: "0%"}}>About</h1>
            <article>
                {schoolParagraph.slice(0, schoolParagraph.length / 0.5) + "..."}
            </article>
            <Button1 txt={"Read More"} path="/about" />
        </div>
        <div className='mottos' style={{height: '100%'}} >
            <MottoVis title={"Motto"} 
                motto={ motto }
            />
            <MottoVis title={"Mission"} 
                motto={ mission }
            />
            <MottoVis title={"Vision"} 
                motto={ vision }
            />
        </div>
    </div>
  )
}

export default IntroSection
