import React from 'react'
import './comp.css'
import './carousel.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { captions } from '../Data/captions';

const MyCarousel = () => {
    function importAll(r) {
        //// Use these line if you like to access arrays using an integer index.
        let images = [];
        r.keys().map(item => { return images.push(r(item)); });
        return images;
    }
    const images = importAll(require.context('../images/group', false, /\.(webp|png|jpe?g|svg)$/));
    return (
        <Carousel >
            {images.map((img, indx)=>{
                return (
                <Carousel.Item key={indx} >
                    <img className='d-block w-100' src={img} alt="orogare" />
                    <Carousel.Caption style={{backgroundColor: "rgb(0,0,0,0.5)"}}>
                        <h3>{captions[indx]}</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

export default MyCarousel