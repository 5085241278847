export const courses = [
    // diploma
  {
    level: "Diploma",
    course:"CIVIL ENGINEERING", 
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE, A HOLDER NEEDS TO HAVE A CRAFT CERTIFICATE"]
  },
    {
    level: "Diploma",
    course:"INFORMATION AND COMMUNICATIONS TECHNOLOGY", 
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE, A HOLDER NEEDS TO HAVE A CRAFT CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"BUILDING TECHNOLOGY",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS  OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"BUSINESS MANAGEMENT",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS  OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"SUPPLY CHAIN MANAGEMENT",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"ACCOUNTING (ATC)",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"ELECTRICAL ENGINEERING",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"SOCIAL WORK AND COMMUNITY DEVELOPMENT",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"LIBRARY SCIENCE",
    duration:'3 years'
  },
  {
    level: "Diploma",
    course:"FASHION AND DESIGN",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT  REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE  NEEDS A HOLDER TO HAVE  A CRAFT MODULE II CERTIFICATE"]
  },
  {
    level: "Diploma",
    course:"SECRETARIAL STUDIES",
    duration:'3 years',
    qualification:["A MINIMUN GRADE OF C-(Minus) IN KCSE EXCEPT IN COMPUTER SCIENCE THAT REQUIRES A MSS OF C (Plain) ","ANY GRADE BELOW C-(Minus) IN KCSE NEEDS A HOLDER TO HAVE A CRAFT MODULE II CERTIFICATE"]
  },

//   craft
  {
    level: "Craft",
    course:"PLUMBING",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"MASONRY TEP",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
      {
    level: "Craft",
    course:"INFORMATION AND COMMUNICATIONS TECHNOLOGY",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"CIVIL ENGINEERING",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"BUILDING TECHNOLOGY",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"INFORMATION TECHNOLOGY",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"BUSINESS MANAGEMENT",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE , THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"SUPPLY CHAIN MANAGEMENT",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE, THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"SECRETARIAL SINGLE AND GROUP",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE, THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"ELECTRICAL INSTALLATION",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE, THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"LIBRARY AND INFORMATION  SCIENCE",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE, THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
  {
    level: "Craft",
    course:"CLOTHING AND TEXTILE",
    duration:'2 years',
    qualification:["A MEAN GRADE OF D(Plain) IN KCSE EXCEPT ICT OR COMPUTER SCIENCE THAT REQUIRES A MSS OF D+(Plus)","ANY GRADE BELOW D(PLAIN) IN KCSE, THE HOLDER NEEDS AN ADDITIONAL ARTISAN CERTIFICATE"],
  },
// Artisan

    {
    level: "Artisan",
    course:"PLUMBING",
    duration:'1 year',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Artisan",
    course:"BUILDING TECHNOLOGY",
    duration:'1 year',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Artisan",
    course:"MASONRY (TEP)",
    duration:'1 year',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Artisan",
    course:"CARPENTRY",
    duration:'1 year',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Artisan",
    course:"MOTOR VEHICLE",
    duration:'1 year',
    qualification:"KCSE Certificate Holder"
  },

//   short courses
{
    level: "Short Specialized Courses",
    course:"ELECTRICAL WIRING",
    duration:'6 Months',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Short Specialized Courses",
    course:"SPRAY PAINTING",
    duration:'4 Months',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Short Specialized Courses",
    course:"CABRO LAYING",
    duration:'4 Months',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Short Specialized Courses",
    course:"DRESS MAKING",
    duration:'6 Months',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Short Specialized Courses",
    course:"COMPUTER PACKAGES",
    duration:'6 Months',
    qualification:"KCSE Certificate Holder"
  },
  {
    level: "Short Specialized Courses",
    course:"HAREDRESSING AND BEAUTY THERAPY",
    duration:'4 Months',
    qualification:"KCSE Certificate Holder"
  }

];
