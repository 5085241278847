import React from 'react'
import ADMISSION from '../images/admissionLetter.jpg'
import FEESTRUCTURE from '../images/fees.jpg'
import LETTER from '../Data/letter.pdf'
import FEE from '../Data/fees.pdf'

const ApplicationDocs = () => {
    return (
        <div className='application_docs'>
            <h2>Admission Letters</h2>
            <div className="main-section">
                <p>Welcome to our college’s admissions download page. Here you can find and download your admission letter and fee structure for the upcoming academic year.</p>
                <p>Deadline for submission is <span>10/09/2024</span></p>
                <div className="container">
                    <h3>Download Admission Letter</h3>

                    <div className="d-container">
                        <div className="topsection">
                            <img src={ADMISSION} alt="admission letter" />
                        </div>
                        <div className="bottom">
                            <a href={LETTER} ><button>Download Letter</button></a>

                        </div>
                    </div>

                </div>

                <div className="container">
                    <h3>Download Fee Structure</h3>

                    <div className="d-container">
                        <div className="topsection">
                            <img src={FEESTRUCTURE} alt="admission letter" />

                        </div>
                        <div className="bottom">
                            <a href={FEE}>
                                <button>Download Fee Structure</button>
                            </a>

                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default ApplicationDocs