import React, { useState } from 'react'
import { BsFacebook, BsPhoneFill, BsTwitter } from 'react-icons/bs'
import { MdMail } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import CallActions from './CallActions'
import './comp.css'

const MobileDropdown = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const click = () =>{
        let div1 = document.getElementById('o1'), div2 = document.getElementById('t2'), div3 = document.getElementById('t3');
        let divs = [div1, div2, div3]
        if(!show){
            return setShow(true)
        }
        divs.forEach(e=>{
            setShow(false)
        })
    }

    const nav = (e) =>{
        navigate(e.target.name)
        setShow(false)
    }
    const transfm = {
        transform: show ? "rotateZ(45deg)" : "rotateZ(0deg)"
    }
     return (
    <div className='dropDown'>
        <button className='dropDownBtn' onClick={click} >
            <div id='o1' style={transfm}/>
            <div id='t2' style={transfm}/>
            <div id='t3' style={transfm}/>
        </button>
        {show && 
        <div className='dropDownList' id='dropDlist'>
            <button className='button3' name='/' 
                onClick={nav}
            >HOME</button>
            <button className='button3' name='/about'  
                onClick={nav}
            >ABOUT</button>
            <button className='button3' name='/application'  
                onClick={nav}
            >ADMISSION PORTAL</button>
            <button className='button3' name='/careers'
                onClick={nav}
            >CAREERS</button>
         <button className='button3' name='/staff' 
                onClick={nav}
            >STAFF</button>
         <button className='button3' name='/tenders' 
                onClick={nav}
            >TENDERS</button>

            <div className='contacts' onBlur={()=>setShow(false)}>
                <CallActions icon={<BsPhoneFill />} link={
                    "tel://+254712345678"
                } />
                <CallActions icon={<BsTwitter />} link={
                    "twitter.com"
                } />
                <CallActions icon={<MdMail />} link={
                    "mailto://progare@gmail.com"
                } />
                <CallActions icon={<BsFacebook />} link={
                    "facebook.com"
                } />
            </div>
        </div>}
    </div>
  )
}

export default MobileDropdown
