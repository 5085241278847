export const captions = [
    "Institute Main Gate",
    "Hair Dressing",
    "Survey Class",
    "Institute Team",
    "Brick Maker",
    "Institute Entrance",
    "Survey Equipment",
    "Soil Testing Machine",
    "Soil Testing Lab",
    "Catering class session",
    "Computer Lab",
    "Brick Maker",
    "Laboratory",
    "New Computer Lab",
    "Administration block",
    "Institute Compound",
    "Technical Drawing Room",
    "Football Team"
]
